import { Form } from 'packages/formidable';
import { UserData } from 'packages/innedit';
import React, { FC } from 'react';

import Layout from '~/components/Layout';
import edition from '~/params/user/edition.json';

import requireUser, { UserProps } from '../utils/requireUser';

const PageEdition: FC<UserProps> = ({ user }) => {
  const handleOnSubmit = async (values: any) => {
    const userData = new UserData({ params: edition });

    await userData.edition(user.id, values);
  };

  return (
    <Layout showNavigation={false}>
      <div className="max-w-md mx-auto">
        <Form
          className="mt-12"
          datas={edition}
          enableReinitialize
          footerProps={{
            className: 'mt-6 flex justify-end',
          }}
          initialValues={user ?? {}}
          name="user"
          onSubmit={handleOnSubmit}
          submitProps={{
            label: 'Mettre à jour',
          }}
        />
      </div>
    </Layout>
  );
};

export default requireUser(PageEdition);
